export default function LogoMark({ className }: { className?: string }) {
  return (
    <div className={className}>
      <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.6839 16.3729L21.7809 21.875V18.1849L14.4079 13.7259C11.984 12.2594 8.87909 12.3041 6.5015 13.84L0 18.0394V21.8023L8.30612 16.4374C8.98201 16.0009 9.76254 15.7826 10.5448 15.7826C11.2858 15.7826 12.0286 15.9794 12.6823 16.3745L12.6839 16.3729Z"
          fill="currentColor"
        />
        <path d="M21.7809 0H0V3.12475H21.7809V0Z" fill="currentColor" />
        <path
          d="M6.94065 6.58648L0 8.73412V12.0159L7.93216 9.56078C8.89967 9.26153 9.89634 9.11108 10.8913 9.11108C11.8862 9.11108 12.8829 9.26153 13.8504 9.56078L21.7826 12.0159V8.73412L14.8419 6.58648C12.2568 5.78628 9.5258 5.78628 6.94065 6.58648Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
}
